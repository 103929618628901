import api, {
    AutomatedUwRecommendation, FieldConfig, ManualLoanEntry,
    PricingFieldName, WebSocketEventType, pricingFieldNameDisplay
} from '@api';
import {
    Button, Link as MuiLink, Step, StepLabel, Stepper, Typography
} from '@mui/material';
import { PaperSaveLoader } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Page from '../components/Page';

import styles from './ManualLoanEntryPage.module.scss';
import AdditionalDetailsStep from './components/AdditionalDetailsStep';
import BorrowersStep from './components/BorrowersStep';
import LoanStep from './components/LoanStep';
import PropertyStep from './components/PropertyStep';


export interface ManualLoanEntryFormValues extends Omit<ManualLoanEntry, 'automatedUwRecommendation'> {
    automatedUwRecommendation?: AutomatedUwRecommendation | '';
}

const defaultValues: Partial<ManualLoanEntry> = {
    borrowers: [ {} ]
};

/**
 * Renders the manual loan entry page
 *
 * @constructor
 */
export default function ManualLoanEntryPage() {
    const { customerId } = useGetCurrentAccount();
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const formMethods = useForm<ManualLoanEntry>({ defaultValues });
    const [ fieldConfigs, setFieldConfigs ] = useState<FieldConfig[]>([]);
    const [ activeStep, setActiveStep ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ customerIdSearch, setCustomerIdSearch ] = useState('');

    const { id: clientId } = useGetCurrentAccount();

    useAsyncEffect(useCallback(async () => {
        try {
            const configs = await api.fieldConfig.getFieldConfigs(clientId);
            setFieldConfigs(configs);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching field configurations', error);
        }
    },
    [ clientId, pageMessage ]));

    const goToPreviousStep = () => setActiveStep(activeStep - 1);

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        if (activeStep === 3) {
            try {
                setLoading(true);
                // Filter form data based on enabled fields
                const enabledFields = fieldConfigs
                    .filter(config => config.enabled)
                    .map(config => config.fieldName);

                const filteredFormData = Object.fromEntries(
                    Object.entries(formData).filter(([ key ]) => {
                        const pricingFieldName = Object.entries(fieldNameToPropertyMap).find(
                            ([ , value ]) => value === key
                        )?.[0] as PricingFieldName | undefined;
                        return pricingFieldName && enabledFields.includes(pricingFieldName);
                    })
                ) as Partial<ManualLoanEntry>;

                // Ensure all required fields are present
                const missingFields = enabledFields.filter(field => {
                    const propertyName = fieldNameToPropertyMap[field];
                    return !(propertyName || '' in filteredFormData);
                });

                if (missingFields.length > 0) {
                    const missingFieldNames = missingFields.map(field => pricingFieldNameDisplay[field]).join(', ');
                    throw new Error(`Missing required fields: ${missingFieldNames}`);
                }

                await api.pricing.priceLoan(
                    clientId, filteredFormData as ManualLoanEntry, customerIdSearch || customerId!
                );
            } catch (error) {
                pageMessage.handleApiError('An error occurred while creating the loan', error);
            } finally {
                setLoading(false);
            }
        } else {
            setActiveStep(activeStep + 1);
        }
    });

    useEffect(() => api.webSocket.subscribe(
        WebSocketEventType.PRICING_COMPLETE,
        () => navigate('./../..')
    ), [ navigate ]);

    function isFieldEnabled(fieldName: PricingFieldName): boolean {
        const config = fieldConfigs.find(config => config.fieldName === fieldName);
        return config?.enabled ?? false;
    }

    return (
        <Page
            header="Price new loan manually"
            variant="centered"
        >
            <Stepper
                activeStep={activeStep}
                className={styles.stepper}
            >
                <Step>
                    <StepLabel>
                        Borrower(s)
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Property
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Loan
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Additional details

                        <Typography
                            variant="caption"
                            className={styles.optionalCaption}
                        >
                            (optional)
                        </Typography>
                    </StepLabel>
                </Step>
            </Stepper>

            <form
                noValidate
                onSubmit={handleSubmit}
            >
                <FormProvider {...formMethods}>
                    {activeStep === 0 ? (
                        <BorrowersStep isFieldEnabled={isFieldEnabled} />
                    ) : activeStep === 1 ? (
                        <PropertyStep
                            onPreviousButtonClick={goToPreviousStep}
                            isFieldEnabled={isFieldEnabled}
                        />
                    ) : activeStep === 2 ? (
                        <LoanStep
                            onPreviousButtonClick={goToPreviousStep}
                            isFieldEnabled={isFieldEnabled}
                        />
                    ) : (
                        <AdditionalDetailsStep
                            setCustomerIdSearch={setCustomerIdSearch}
                            onPreviousButtonClick={goToPreviousStep}
                            isFieldEnabled={isFieldEnabled}
                            submitButton={(
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            )}
                        />
                    )}
                </FormProvider>
            </form>

            <Typography>
                Have a loan file?
                {' '}

                <MuiLink
                    component={Link}
                    to="./../.."
                >
                    Upload loans
                </MuiLink>

                {' '}
                instead
            </Typography>

            <PaperSaveLoader loading={loading} />
        </Page>
    );
}

const fieldNameToPropertyMap: Record<PricingFieldName, keyof ManualLoanEntry | null> = {
    [PricingFieldName.INTEREST_RATE]: 'interestRate',
    [PricingFieldName.LOCK_PERIOD]: 'lockPeriod',
    [PricingFieldName.BASE_LOAN_AMOUNT]: 'baseLoanAmount',
    [PricingFieldName.CUSTOMER_LOAN_NUMBER]: 'customerLoanNumber',
    [PricingFieldName.PROP_STATE]: 'propertyState',
    [PricingFieldName.PROP_COUNTY]: 'propertyCounty',
    [PricingFieldName.PROP_ZIP]: 'propertyZipCode',
    [PricingFieldName.APPRAISED_VALUE]: 'appraisedValue',
    [PricingFieldName.SALES_PRICE]: 'salesPrice',
    [PricingFieldName.PROPERTY_TYPE]: 'propertyType',
    [PricingFieldName.LOAN_TYPE]: 'loanType',
    [PricingFieldName.AMORT_TYPE]: 'amorType',
    [PricingFieldName.LOAN_TERM]: 'loanTerm',
    [PricingFieldName.LOAN_PURPOSE]: 'loanPurpose',
    [PricingFieldName.OCCUPANCY_TYPE]: 'occupancyType',
    [PricingFieldName.FICO_CREDIT_SCORE]: 'loanFICO',
    [PricingFieldName.DTI]: 'dti',
    [PricingFieldName.QUALIFYING_TOTAL_INCOME]: 'qualifyingTotalIncome',
    [PricingFieldName.PROPOSED_HOUSING_PAYMENT]: 'proposedHousingPayment',
    [PricingFieldName.OTHER_PAYMENTS]: 'otherPayments',
    [PricingFieldName.UNITS]: 'propertyUnits',
    [PricingFieldName.DOC_TYPE]: 'documentationType',
    [PricingFieldName.SUBORDINATED_LOAN_AMOUNT]: 'subordinatedBalance',
    [PricingFieldName.FIRST_TIME_HOME_BUYER]: null, // Not in ManualLoanEntry
    [PricingFieldName.ESCROWS_FLAG]: 'escrowsFlag',
    [PricingFieldName.ESCROW_RESERVES]: 'escrowReserves',
    [PricingFieldName.INTEREST_ONLY_FLAG]: 'interestOnlyFlag',
    [PricingFieldName.AUTOMATED_UW_SYSTEM]: 'automatedUwSystem'
};
